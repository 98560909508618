import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import Content, { HTMLContent } from '../components/Content';
import Helmet from 'react-helmet';

export const AboutPageTemplate = ({ title, content, contentComponent, image, phone, email }) => {
  const PageContent = contentComponent || Content;

  return (
    <>
      <div className="main-content">
        <div className="section-light">
          <div className="container">
            <div className="column is-12">
              <h1 className="title has-text-centered " style={{ marginBottom: 45 }}>
                {title}
              </h1>
            </div>
            <div className="columns" style={{ margin: 0 }}>
              <div className="column is-6">
                {image ? (
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: image,
                      alt: `featured image thumbnail for post ${title}`
                    }}
                  />
                ) : null}
              </div>
              <div className="column is-6 has-vertically-aligned-content" data-aos="fade-right">
                <PageContent className="content" content={content} />
                <br />
                <div className="column is-12 has-text-centered-mobile">
                  <div className="columns is-multiline">
                    <div className="column is-6">
                      <p className="heading">
                        <strong>Give us a call !</strong>
                      </p>
                      <p className="subheading" style={{ fontSize: 16 }}>
                        Tel: {phone}
                      </p>
                      <p className="subheading" style={{ fontSize: 16 }}>
                        Fax: 86-755-82905533
                      </p>
                    </div>
                    <div className="column is-6">
                      <p className="heading">
                        <strong>Address</strong>
                      </p>
                      <p className="subheading" style={{ fontSize: 16 }}>
                        9 Floor, Building 9th, Minghaolicheng North of Shangxing Road, Shajing, Baoan, Shenzhen,
                        Guangdong, China
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  phone: PropTypes.string,
  email: PropTypes.string
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <Helmet titleTemplate="%s | About"></Helmet>
      <div className="my-header">
        <div className="breadcrumb-area breadcrumb-bg-2">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1 className="breadcrumb-title">About</h1>
                <ul className="breadcrumb-list">
                  <li className="breadcrumb-list__item">
                    <a href="/">HOME</a>
                  </li>
                  <li className="breadcrumb-list__item breadcrumb-list__item--active">ABOUT</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        image={post.frontmatter.image}
        email={post.frontmatter.email}
        phone={post.frontmatter.phone}
        content={post.html}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        email
        phone
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
